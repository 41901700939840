<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadSuppliers(true)" />
                </div>
                <div class="col-auto">
                    <el-button type="primary" @click="showSupplierDialog(null)">添加</el-button>
                    <el-button @click="importSuppliersDialogVisible=true">导入</el-button>
                </div>
            </div>
            <el-table :data="suppliers.data" class="mt-2">
                <el-table-column prop="registeredUser.name" label="企业名称" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.registeredUser.id" target="_blank">
                            {{scope.row.registeredUser.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="registeredUser.idCard" label="统一社会信用代码" sortable />
                <el-table-column prop="legalPersonName" label="法人" sortable />
                <el-table-column prop="legalPersonPhone" label="法人电话" sortable />
                <el-table-column prop="contactName" label="联系人" sortable />
                <el-table-column prop="contactPhone" label="联系电话" sortable />
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showSupplierDialog(scope.row)">修改</el-button>
                        <el-button type="text" class="text-danger" @click="deleteSupplier(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="suppliers.totalRecords"
                :current-page="suppliersPagination.currentPage" :page-size="suppliersPagination.pageSize" class="mt-3"
                @current-change="suppliersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="supplierDialogVisible" title="供应商" :close-on-click-modal="false" width="800px">
            <el-form>
                <el-form-item label="注册用户" class="form-item-required">
                    <el-input :value="supplier.registeredUser.name" readonly placeholder="注册用户">
                        <template #append v-if="supplier.id==undefined">
                            <el-button @click="selectRegisteredUserDialogVisible=true">选择</el-button>
                        </template>
                    </el-input>
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="法人" class="form-item-required">
                            <el-input v-model="supplier.legalPersonName" placeholder="法人" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="法人电话" class="form-item-required">
                            <el-input v-model="supplier.legalPersonPhone" placeholder="法人电话" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="联系人" class="form-item-required">
                            <el-input v-model="supplier.contactName" placeholder="联系人" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系电话" class="form-item-required">
                            <el-input v-model="supplier.contactPhone" placeholder="联系电话" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="主营品牌" class="form-item-required">
                    <el-input v-model="supplier.mainBrand" placeholder="主营品牌" />
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="经营区划" class="form-item-required">
                            <el-input :value="supplier.regionName" readonly placeholder="经营区划">
                                <template #append>
                                    <el-button @click="selectRegionDialogVisible=true">选择</el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地址" class="form-item-required">
                            <el-input v-model="supplier.address" placeholder="地址" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="开户行" class="form-item-required">
                            <el-input v-model="supplier.openingBank" placeholder="开户行" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开户名" class="form-item-required">
                            <el-input v-model="supplier.accountName" placeholder="开户名" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="行号" class="form-item-required">
                            <el-input v-model="supplier.bankNumber" placeholder="行号" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="银行账号" class="form-item-required">
                            <el-input v-model="supplier.accountNumber" placeholder="银行账号" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="备注">
                    <el-input v-model="supplier.remark" placeholder="备注" />
                </el-form-item>

                <div class="mt-3">
                    <div>其他附件</div>
                    <div class="border rounded mt-2 p-1">
                        <picture-uploader v-model="supplier.otherAttachments" />
                    </div>
                </div>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveSupplier">确定</el-button>
                <el-button @click="supplierDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-registered-user-dialog v-model="selectRegisteredUserDialogVisible" :is-enterprise="true"
            @select="selectRegisteredUser" />

        <select-region-dialog v-model="selectRegionDialogVisible" @select="selectRegion" />

        <import-dialog v-model="importSuppliersDialogVisible" title="导入" action="/api/Supplier/ImportSuppliers"
            :data="{companyId:$store.state.companyId,isEnterprise:true}" template="/templates/企业供应商导入模板.xls"
            @success="loadSuppliers" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                suppliers: {},
                suppliersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                supplier: { registeredUser: {} },
                supplierDialogVisible: false,
                selectRegisteredUserDialogVisible: false,
                selectRegionDialogVisible: false,
                importSuppliersDialogVisible: false,
            };
        },
        methods: {
            async loadSuppliers(resetPage = false) {
                if (resetPage) {
                    this.suppliersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Supplier/GetEnterpriseSuppliers', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 3,
                        key: this.key,
                        pageIndex: this.suppliersPagination.currentPage - 1,
                        pageSize: this.suppliersPagination.pageSize,
                    }
                });
                this.suppliers = response.data;
            },
            suppliersPaginationCurrentChange(page) {
                this.suppliersPagination.currentPage = page;
                this.loadSuppliers();
            },
            showSupplierDialog(supplier) {
                if (supplier) {
                    this.supplier = JSON.parse(JSON.stringify(supplier));
                }
                else {
                    this.supplier = {
                        registeredUser: {},
                        otherAttachments: [],
                    };
                }
                this.supplierDialogVisible = true;
            },
            selectRegisteredUser(user) {
                this.supplier.registeredUser.id = user.id;
                this.supplier.registeredUser.name = user.name;
                this.supplier.legalPersonName = user.legalPersonName;
                this.supplier.legalPersonPhone = user.phone;
                this.supplier.contactName = user.name;
                this.supplier.contactPhone = user.phone;
            },
            selectRegion(region) {
                this.supplier.regionId = region.id;
                this.supplier.regionName = region.name;
            },
            async saveSupplier() {
                if (this.supplier.id == undefined) {
                    await this.$axios.post('/api/Supplier/AddEnterpriseSupplier', {
                        registeredUserId: this.supplier.registeredUser.id,
                        legalPersonName: this.supplier.legalPersonName,
                        legalPersonPhone: this.supplier.legalPersonPhone,
                        contactName: this.supplier.contactName,
                        contactPhone: this.supplier.contactPhone,
                        mainBrand: this.supplier.mainBrand,
                        regionId: this.supplier.regionId,
                        address: this.supplier.address,
                        openingBank: this.supplier.openingBank,
                        accountName: this.supplier.accountName,
                        bankNumber: this.supplier.bankNumber,
                        accountNumber: this.supplier.accountNumber,
                        remark: this.supplier.remark,
                        otherAttachments: this.supplier.otherAttachments,
                    });
                    this.supplierDialogVisible = false;
                    this.loadSuppliers();
                }
                else {
                    await this.$axios.post('/api/Supplier/UpdateEnterpriseSupplier', {
                        id: this.supplier.id,
                        legalPersonName: this.supplier.legalPersonName,
                        legalPersonPhone: this.supplier.legalPersonPhone,
                        contactName: this.supplier.contactName,
                        contactPhone: this.supplier.contactPhone,
                        mainBrand: this.supplier.mainBrand,
                        regionId: this.supplier.regionId,
                        address: this.supplier.address,
                        openingBank: this.supplier.openingBank,
                        accountName: this.supplier.accountName,
                        bankNumber: this.supplier.bankNumber,
                        accountNumber: this.supplier.accountNumber,
                        remark: this.supplier.remark,
                        otherAttachments: this.supplier.otherAttachments,
                    });
                    this.supplierDialogVisible = false;
                    this.loadSuppliers();
                }
            },
            async deleteSupplier(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Supplier/DeleteSupplier', { id: id });
                this.loadSuppliers();
            },
        },
        created() {
            this.loadSuppliers();
        },
    };
</script>